// Hooks
import useLocalize from '../../locales/hook'
import { useRouteMatch } from 'react-router'
import useParams from '../../utils/lib/useParams'
import { useIsSealed } from '../../redux/sealing/hooks'
import { useScrutin } from '../../redux/scrutins/hooks'
import { useCurrentVoteStatus } from '../../redux/app/statusHook'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUninominalCandidates, useUserVote } from '../../redux/uninominal/hooks'

// Components
import Icon from '../../atoms/Icon/Icon'
import Button from '../../atoms/Button/Button'
import HelpItem from '../../components/HelpItem/HelpItem'
import WarningModal from '../../atoms/WarningModal/WarningModal'
import { IonContent, IonPage, useIonRouter } from '@ionic/react'
import VoteElectionCandidate from './partials/VoteElectionCandidate'

// Style & Types & Utils
import './VoteElections.scss'
import ROUTES from '../../routes/constants/routes'
import IconTypes from '../../utils/types/IconTypes'
import VoteInfoConfirmationModal from '../../components/VoteInfoConfirmationModal/VoteInfoConfirmationModal'
import { useValidationParams } from '../../redux/configuration/hooks'

export const WHITE_VOTE = -1

const VoteElections: React.FC<any> = () => {
  const t = useLocalize()
  const { id } = useParams(`${ROUTES.VOTE_ELECTION}/:id`)
  const isSealed = useIsSealed()
  const {
    push,
    routeInfo: { pathname },
  } = useIonRouter()
  const { url } = useRouteMatch()
  const voteUni = useUserVote(id)
  const { scrutin } = useScrutin(id, true, 'uni')
  const candidateIds = useUninominalCandidates(id)
  const { voteStarted, voteOngoing, voteEnded } = useCurrentVoteStatus()
  const { seatNb, subTitle, blankVoteEnabled } = scrutin || {}
  const { modalType } = useValidationParams()

  // States
  const [voteStatus, setVoteStatus] = useState<boolean>(false)
  const [isWhiteModal, setIsWhiteModal] = useState<boolean>(false)
  const [selectedCandidates, setCandidates] = useState<number[]>([])
  const [isValidationModalOpen, setValidationModalOpen] = useState<boolean>(false)

  // Memos
  const isPreview = useMemo(() => !isSealed || !voteOngoing, [isSealed, voteOngoing])

  const candidatesHeader = useMemo(() => {
    const whiteVote = selectedCandidates.includes(WHITE_VOTE)
    let wording = `${whiteVote ? 0 : selectedCandidates.length} / ${seatNb}`
    if (blankVoteEnabled && (whiteVote || !selectedCandidates.length)) {
      wording += ` - “${t('white_vote')}”`
    }
    return wording
  }, [blankVoteEnabled, seatNb, selectedCandidates, t])

  const tParams = useMemo(() => {
    let myVotes = ''
    if (selectedCandidates.includes(WHITE_VOTE) || !selectedCandidates.length) {
      myVotes = t('white_vote')
    } else if (selectedCandidates.length === 1) {
      myVotes = t('one_candidate')
    } else {
      myVotes = t('multi_candidates', { nb: selectedCandidates.length })
    }
    return {
      seatNb,
      myVotes: myVotes,
    }
  }, [seatNb, selectedCandidates, t])

  // Handlers
  const handleSelect = useCallback((id: number) => {
    if (id === WHITE_VOTE) {
      setCandidates((prev) => (prev.includes(WHITE_VOTE) ? [] : [WHITE_VOTE]))
    } else {
      setCandidates((prev) => {
        if (prev.includes(id)) {
          return prev.filter((pId) => pId !== id)
        }
        if (prev.includes(WHITE_VOTE)) {
          return [id]
        }
        return prev.concat([id])
      })
    }
  }, [])

  const handleCancel = useCallback(() => {
    if (voteStatus) {
      setVoteStatus(false)
    } else {
      push(ROUTES.HOME)
    }
  }, [voteStatus, push])

  const handleVote = useCallback(
    ({ personalInformation, password }) => {
      if (!isValidationModalOpen && modalType !== 'none') {
        setValidationModalOpen(true)
      } else {
        setValidationModalOpen(false)
        voteUni(
          selectedCandidates,
          personalInformation || password ? { personalInformation, password } : undefined,
          () => {
            setVoteStatus(false)
            setCandidates([])
            push(ROUTES.BALLOT_RECEIPT)
          }
        )
      }
    },
    [isValidationModalOpen, modalType, push, selectedCandidates, voteUni]
  )

  // Next Steps
  const handleNextStep = useCallback(() => {
    if (!selectedCandidates.length) {
      setIsWhiteModal(true)
    } else {
      setVoteStatus(true)
    }
  }, [selectedCandidates.length])
  const handleNextWhite = useCallback(() => {
    setCandidates([WHITE_VOTE])
    setIsWhiteModal(false)
    setVoteStatus(true)
  }, [])

  useEffect(() => {
    if ((voteEnded || !!scrutin?.me?.signature) && pathname === url) {
      push(ROUTES.HOME)
    }
  }, [pathname, push, scrutin, url, voteEnded])

  // TO DO: Check if user as access, otherwise redirect to home
  if (!scrutin) return null
  return (
    <IonPage className={'myCurrentPage headerSpace'}>
      <IonContent id='VoteElections' className='page headerSpace' scrollEvents>
        <HelpItem pollId={id} />
        <div className='header'>
          <Icon icon={IconTypes.CHEVRON_LEFT} onClick={handleCancel} />
          <div className='back' onClick={handleCancel}>
            {t('back')}
          </div>
        </div>
        <div className='pageTitle'>{scrutin?.title}</div>
        <div className={`message ${voteStatus ? 'ready' : ''}`}>
          <div className='iconSide'>
            <Icon icon={voteStatus ? IconTypes.ALERT_OCTAGON : IconTypes.INFO} />
          </div>
          <div className='info'>
            <div className='title'>
              {t(voteStatus ? 'uninominal_voting_title_2' : 'uninominal_voting_title_1')}
            </div>
            <div className='desc'>
              {subTitle ||
                t(
                  voteStatus ? 'uninominal_voting_message_2' : 'uninominal_voting_message_1',
                  tParams
                )}
            </div>
          </div>
        </div>
        <div className='candidates'>
          <div className='headTitle'>{`${t('candidates')} ${
            isPreview ? '' : candidatesHeader
          }`}</div>
          <div className='list'>
            {candidateIds?.map((id) => (
              <VoteElectionCandidate
                id={id}
                key={id}
                locked={voteStatus}
                isPreview={isPreview}
                onClick={handleSelect}
                selected={selectedCandidates.includes(id)}
                disabled={!voteStatus && selectedCandidates.length >= seatNb}
              />
            ))}
            {blankVoteEnabled && !isPreview && (
              <VoteElectionCandidate
                id={WHITE_VOTE}
                onClick={handleSelect}
                locked={voteStatus}
                selected={selectedCandidates.includes(WHITE_VOTE)}
              />
            )}
          </div>
        </div>
        {!isPreview && (
          <div className='footer'>
            <div className='recap_votes'>
              {t('vote_shares_for_poll', {
                shares: scrutin?.myShares,
                weightType: t(scrutin?.weightingType),
              })}
            </div>
            <div className='buttonsBox'>
              <Button label={t('back')} onClick={handleCancel} icon={IconTypes.CHEVRON_LEFT} />
              <Button
                fill
                label={t(voteStatus ? 'vote' : 'next_step')}
                disabled={!voteStarted || (!blankVoteEnabled && selectedCandidates.length === 0)}
                onClick={voteStatus ? handleVote : handleNextStep}
              />
            </div>
          </div>
        )}
        <VoteInfoConfirmationModal
          visible={isValidationModalOpen}
          setVisible={setValidationModalOpen}
          onConfirm={handleVote}
        />
        <WarningModal
          visible={isWhiteModal}
          setVisible={setIsWhiteModal}
          onConfirm={handleNextWhite}
          title={t('no_vote_white_vote')}
          message={{
            description: t('no_vote_white_vote_desc'),
          }}
        />
      </IonContent>
    </IonPage>
  )
}

export default VoteElections
