// Hooks
import useAppLogout from './logoutHook'
import { useIonRouter } from '@ionic/react'
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCurrentVoteStatus } from './statusHook'
import { useAppMode } from '../appConfig/hooks'
// Types & Utils
import ROUTES, { isLoggedRoutes } from '../../routes/constants/routes'
import { setAppStarted, setErrorStatus } from './redux'
import { deleteCookie, getCookie, setCookie } from '../../utils/security/cookies'

// Services
import { requestRoles } from '../roles/services'
import { requestModes, requestPlatformHashes, requestStatus } from '../appConfig/services'
import { requestProfile } from '../profile/services'
import { requestElections } from '../scrutins/services'
import { requestSendStatus } from '../sendCodes/services'
import { requestInitSealingStatus } from '../sealing/services'
import { requestGetConfigInfos, requestIdRules, requestVoteInfos } from '../configuration/services'
import { CORRESPONDANCE_ROLES } from '../roles/constants'
import { requestTheme } from '../theme/services'
import { requestGetGeneralHelpFiles } from '../helpFiles/services'
import { useIsCSE } from '../configuration/hooks'
import { usePrivileges } from '../auth/hooks'
import { getRefreshToken, getToken } from '../auth/utils'
import { requestRenewToken } from '../auth/services'

// const IdleTimer = 1000 * 10; // 60s
const IdleTimer = 4 // 4min
const LogoutTimer = 60 // 60s
const CONFIRM = 'confirm'

export const useInitApp = () => {
  const dispatch = useAppDispatch()

  const {
    routeInfo: { pathname: path },
  } = useIonRouter()

  const isLogged = useAppSelector((state) => !!state.auth.token)
  // Roles
  const { CONFIGURE_ELECTION } = usePrivileges()

  // Datas
  const privileges = useAppSelector((state) => state.auth.privileges || {})
  const establishmentId = useAppSelector((state) => state.auth.establishmentId)
  const establishments = useAppSelector((state) => state.appConfig.establishments)
  const purpose = useAppSelector((state) => state.sendCodes.currentPurpose)

  // Dispatchs
  const getRoles = useCallback(() => dispatch(requestRoles()), [dispatch])
  const getTheme = useCallback(() => dispatch(requestTheme()), [dispatch])
  const getProfile = useCallback(() => dispatch(requestProfile()), [dispatch])
  const getElections = useCallback(
    () => dispatch(requestElections({ canUseAdmin: CONFIGURE_ELECTION })),
    [dispatch, CONFIGURE_ELECTION]
  )
  const getRequestStatus = useCallback(() => dispatch(requestStatus()), [dispatch])
  const getRequestMode = useCallback(() => dispatch(requestModes()), [dispatch])
  const getConfigInfos = useCallback(() => dispatch(requestGetConfigInfos()), [dispatch])
  const getGeneralHelpFiles = useCallback(() => dispatch(requestGetGeneralHelpFiles()), [dispatch])
  const getIdRules = useCallback(() => dispatch(requestIdRules()), [dispatch])
  const getVoteInfos = useCallback(
    (establishments) => dispatch(requestVoteInfos({ establishments })),
    [dispatch]
  )
  const getSendCodesStatus = useCallback(
    () => dispatch(requestSendStatus({ purpose })),
    [purpose, dispatch]
  )
  const getInitSealing = useCallback(
    () => dispatch(requestInitSealingStatus({ isAdmin: CONFIGURE_ELECTION })),
    [dispatch, CONFIGURE_ELECTION]
  )
  const getRequestHashes = useCallback(() => dispatch(requestPlatformHashes()), [dispatch])

  useEffect(() => {
    if (!establishmentId) {
      getVoteInfos(establishments)
    } else {
      getVoteInfos([{ id: establishmentId }])
    }
  }, [establishments, establishmentId, getVoteInfos])

  const myPrivileges = useMemo(
    () =>
      Object.entries(privileges).reduce(
        (acc: any, [key, value]: any) => ({
          ...acc,
          [key]: value.isEnabled,
        }),
        {}
      ),
    [privileges]
  )

  const checkPermissions = useCallback(() => {
    if (!isLogged && isLoggedRoutes(path)) {
      // If not logged, cannot access page
      return (window.location.href = ROUTES.LOGIN)
    } else if (isLogged) {
      if (!isLoggedRoutes()) {
        // No need to be on login page if already logged in
        return (window.location.href = ROUTES.HOME)
      }
    }
    return CONFIRM
  }, [path, isLogged, myPrivileges])

  const initApp = useCallback(() => {
    if (path.length > 1 && path.endsWith('/')) {
      // Path formality
      return (window.location.href = path.slice(0, path.length - 1))
    }

    getTheme()
    getConfigInfos()
    getGeneralHelpFiles()
    getRequestStatus()
    getRequestMode()
    if (checkPermissions() === CONFIRM && isLoggedRoutes()) {
      getProfile()
      getRoles()
      getElections()
      getRequestHashes()
      getSendCodesStatus()
      getInitSealing()
      getIdRules()
    }

    dispatch(setAppStarted(true))
  }, [
    path,
    getTheme,
    getConfigInfos,
    getGeneralHelpFiles,
    checkPermissions,
    dispatch,
    getRequestStatus,
    getRequestMode,
    getProfile,
    getRoles,
    getElections,
    getRequestHashes,
    getSendCodesStatus,
    getInitSealing,
    getIdRules,
  ])

  useEffect(() => {
    checkPermissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  return initApp
}

// Idle Expiration
export const useIdle = () => {
  const appLogout = useAppLogout()
  const dispatch = useAppDispatch()
  const isAdmin = useAppSelector(
    (state) =>
      state.auth.role === CORRESPONDANCE_ROLES.admin ||
      state.auth.role === CORRESPONDANCE_ROLES.supervisor
  )
  const idleTimer = useRef<NodeJS.Timer | null>(null)
  const idleTimerDuration =
    useAppSelector((state) =>
      isAdmin
        ? state?.configuration?.config?.adminSessionDuration
        : state?.configuration?.config?.userSessionDuration
    ) || IdleTimer

  const isLogged = useAppSelector((state) => !!state.auth.token)

  const [visible, setVisible] = useState<boolean>(false)
  const [isIdle, setIsIdle] = useState<number | null>(null)
  const idleStartDateTime = useRef<number | null>(null)

  const wasIdle = useRef<boolean>(getCookie('idle') === 'active' && !isLogged)
  const wasExpired = useRef<boolean>(getCookie('expired') === 'active' && !isLogged)

  useEffect(() => {
    setTimeout(() => {
      deleteCookie('idle')
      deleteCookie('expired')
    }, 1500)
  }, [])

  // Canceling Idle time
  const onCancelIdle = useCallback(() => {
    if (idleTimer.current) {
      clearInterval(idleTimer.current)
      idleTimer.current = null
    }

    setVisible(false)
    idleStartDateTime.current = null
    setTimeout(() => setIsIdle(null), 250)
    sendMessage('cancel')
  }, [])

  // Receiving cross tab about idle actions
  const onMessage = useCallback((message) => {
    switch (message) {
      case 'reload':
        return setTimeout(() => window.location.reload(), 1000)
      case 'cancel': {
        if (idleTimer.current) {
          clearInterval(idleTimer.current)
          idleTimer.current = null
        }
        setVisible(false)
        idleStartDateTime.current = null
        setTimeout(() => setIsIdle(null), 250)
      }
    }
  }, [])

  // Logout user
  const onLogout = useCallback((shouldSetIdle = false) => {
    if (idleTimer.current) {
      clearInterval(idleTimer.current)
      idleTimer.current = null
    }

    appLogout()

    if (shouldSetIdle) {
      setCookie('idle', 'active')
    }
    setVisible(false)
    idleStartDateTime.current = null
    sendMessage('reload')
  }, [])

  /*
   *   Once the Idle Time is off
   *   we give "LogoutTimer" time for the user to
   *   either cancel automatic logout or logout right away
   */
  const handleOnIdle = useCallback(() => {
    if (idleTimer.current) return

    if (isLogged) {
      idleStartDateTime.current = Date.now()
      setIsIdle(LogoutTimer)
      setVisible(true)

      idleTimer.current = setInterval(() => {
        setIsIdle((prevState) => {
          if (idleStartDateTime.current) {
            //Ajout d'une sécurité pour gérér le cas de l'onglet en arriere plan qui ne rafraichit le compte à rebour que toutes les 30s/1 minute
            prevState = LogoutTimer - Math.round((Date.now() - idleStartDateTime.current) / 1000)
          }
          if (prevState !== null && prevState <= 0) {
            onLogout(true)
            return null
          }
          return prevState !== null && prevState > 0 ? --prevState : null
        })
      }, 1000)
    }
  }, [isLogged, isIdle, onLogout])

  // Idle Timer
  // sendMessage allow us to launch onMessage from other tabs
  const { message: sendMessage } = useIdleTimer({
    crossTab: true,
    timers: workerTimers, // For backgrounded tab throttle timers : https://idletimer.dev/docs/getting-started/new#web-worker-timers // https://github.com/SupremeTechnopriest/react-idle-timer/issues/271
    syncTimers: 250,
    timeout: idleTimerDuration * 1000 * 60,
    onMessage,
    onIdle: handleOnIdle,
  })
  const { getLastActiveTime } = useIdleTimer({
    onIdle: handleOnIdle,
    debounce: 500,
  })

  // This function helps ensure that the token is periodically checked and renewed, keeping the user session active.
  // It also avoids unnecessary token renewals when in demo mode or when the session is inactive

  const handleToken = (isDemo) => {
    // Retrieve the 'timerIDToken' cookie, which stores the ID of the previous token renewal timer
    const oldTimer = getCookie('timerIDToken')

    // Set up a new interval (timer) to handle the token every 420000 milliseconds (7 minutes)
    var timerID = setInterval(function () {
      // Clear the old interval (previous timer) using the stored 'timerID' from the cookie
      clearInterval(parseInt(oldTimer))

      const token = getToken()

      if (!!!token) {
        return clearInterval(timerID)
      }

      let futureDate
      const lastActiveTime = getLastActiveTime()
      const now = new Date()

      if (lastActiveTime) {
        futureDate = new Date(
          lastActiveTime.setMinutes(lastActiveTime.getMinutes() + idleTimerDuration)
        )
      }

      // If 'futureDate' is in the past (before 'now') and 'lastActiveTime' is valid, exit the function without doing anything
      // IF TRUE it means it has been idle for idleTimerDuration or more
      if (futureDate < now && lastActiveTime) return
      // If a valid token exists, dispatch an action to renew the token using the access token and refresh token
      if (token) {
        dispatch(
          requestRenewToken({
            access_token: token,
            refresh_token: getRefreshToken(isDemo),
          })
        )
      } else {
      }
    }, 300000)
    // Store the current 'timerID' in a cookie to track the active interval
    setCookie('timerIDToken', timerID.toString())
  }

  return {
    isIdle,
    isIdleActive: visible,
    wasIdle: wasIdle.current,
    wasExpired: wasExpired.current,
    onLogout,
    onCancelIdle,
    sendMessage,
    handleToken,
  }
}

export const useAppStatus = () => {
  const isLoading = useAppSelector((state) => state.app.isLoading)
  const appStarted = useAppSelector((state) => state.app.appStarted)
  return { appStarted, isLoading }
}

export const useAllowQuit = () => {
  const { voteOngoing } = useCurrentVoteStatus()
  const isCSE = useIsCSE()
  const csePolls = useAppSelector((state) => state.scrutins.elections)
  const nonCsePolls = useAppSelector((state) => state.mePolls.pollById)

  let nextPollToTake = ''
  let isOneNotVoted = false
  if (isCSE) {
    isOneNotVoted = csePolls?.some(({ colleges }) => {
      return Object.values(colleges)?.some((college: any) => {
        return Object.values(college.polls)?.some((poll: any) => {
          const isPollNotVoted = poll.me
            ? !poll.me.signature && poll.status !== 'DEFICIENCY'
            : false
          if (isPollNotVoted) {
            nextPollToTake = poll.id
          }
          return isPollNotVoted
        })
      })
    })
  } else {
    isOneNotVoted = Object.values(nonCsePolls)?.some((poll: any) => {
      const isPollNotVoted = poll.me ? !poll.me.signature : false
      if (isPollNotVoted) {
        nextPollToTake = poll.id
      }
      return isPollNotVoted
    })
  }

  return {
    shouldDisplayQuitModal: voteOngoing && isOneNotVoted,
    nextPollToTake,
  }
}

const ALLOWED_RESET = ['DEV', 'PREPROD']
export const useAllowProdReset = () => {
  const env = useAppSelector((state) => state.app.env)

  return ALLOWED_RESET.includes(env)
}

export const useAppError = () => {
  const dispatch = useAppDispatch()
  const errorStatus = useAppSelector((state) => state.app.errorState)
  const setError = useCallback((value) => dispatch(setErrorStatus(value)), [dispatch])

  return {
    setError,
    isError: !!errorStatus,
    errorTitle: errorStatus?.title,
    errorMessage: errorStatus?.message,
    errorParams: errorStatus?.messageParams,
  }
}
