import { createSlice } from '@reduxjs/toolkit'
import { deleteAllCookies } from '../../utils/security/cookies'
import { clearSessionStorage } from '../../utils/security/localStorage'
import { requestMeSealing } from '../sealing/services'

// Services
import { requestLoginStates, requestRenewTokenStates } from './services'

// Utils
import { getTokenInfo } from './utils'

const defaultInfo: AuthState = {
  token: null,
  exp: null,
  needsPersonal: false,
  needMFAValidation: false,
  mfaValidationInfo: null,
  needMFACode: false,
  loggingInAdditionnal: false,
  cellMemberId: null,
}

// Define a type for the slice state
export type AuthState = {
  token?: string | null
  role?: number
  exp?: number | null
  error?: boolean
  isLoading?: boolean
  needsPersonal: boolean
  needMFAValidation: boolean
  mfaValidationInfo: {
    uri: string
    account: string
    key: string
  } | null
  needMFACode: boolean
  loggingInAdditionnal: boolean
  cellMemberId?: number | string | null
}

// Define the initial state using that type
const initialState: AuthState = {
  ...getTokenInfo(),
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearLogout: (state) => {
      Object.assign(state, defaultInfo)
      deleteAllCookies()
      clearSessionStorage()
      return defaultInfo
    },
  },
  extraReducers: (builder) => {
    requestLoginStates(builder)
    requestRenewTokenStates(builder)

    // Add the handler for requestMeSealing.fulfilled
    builder.addCase(requestMeSealing.fulfilled, (state, { payload }) => {
      state.cellMemberId = payload.id
    })
  },
})

export const { clearLogout: authLogout } = auth.actions
